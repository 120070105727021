.map-container {
    position: relative;
    height: 250px; 
    width: 100%;
    z-index: 1;
  }
  
  .widget_finoptissocialiconwi_widget {
    margin: 20px;  
  }
  
