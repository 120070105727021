.charter-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 20px;
  background-color: #f4f4f4;
}

.charter-header {
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  background-color: #fff;
  color: black;
  padding: 20px 0;
}

.charter-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
  text-align: center;
  padding: 20px;
}

.charter-logo {
  width: 150px;
  height: auto;
  margin: 0 10px;
}

.charter-logo1 {
  width: 300px;
  height: auto;
  margin: 0 10px;
}

.charter-title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  flex: 1;
  text-align: center;
}


.language-switcher {
  margin-top: 10px;
}

.language-switcher button {
  background-color: #fff;
  color: #00274d;
  border: 1px solid #00274d;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.language-switcher button:hover {
  background-color: #f39c12;
  color: white;
}

.charter-content {
  width: 90%;
  max-width: 1200px;
  background-color: white;
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 40px;
}

.mission-section,
.vision-section,
.commitment-section {
  margin-bottom: 30px;
  text-align: center;
}

.mission-section h2,
.vision-section h2,
.commitment-section h2 {
  font-size: 1.75rem;
  color: #00274d;
  margin-bottom: 15px;
}

.mission-section p,
.vision-section p {
  font-size: 1.1rem;
  line-height: 1.6;
}

.commitment-section table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.nested-table{
  width: 100%;
  height: auto;
  border-collapse: collapse;
}

.commitment-section th,
.commitment-section td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.commitment-section th {
  background-color: #5b92e5;
  color: white;
}

.commitment-section td {
  background-color: #f9f9f9;
}

.commitment-section  a {
  color:#4673b6;
  text-decoration: underline;
}

.commitment-section tr:nth-child(even) td {
  background-color: #fff;
}

.commitment-section tr:hover td {
  background-color: #f1f1f1;
}

.commitment-section h3,
.commitment-section h4 {
  font-size: 1.25rem;
  margin-top: 20px;
  color: #00274d;
}

.commitment-section p {
  font-size: 1rem;
  margin-top: 5px;
}



.commitment-section td[colspan="4"],
.commitment-section td[colspan="2"] {
  text-align: center;
}

.commitment-section td[colspan="4"] {
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .charter-header-content {
    flex-direction: column;
  }

  .charter-logo {
    width: 150px;
  }

  .charter-title {
    font-size: 1.8rem;
  }

  .charter-content {
    padding: 20px;
  }



  .mission-section h2,
  .vision-section h2,
  .commitment-section h2 {
    font-size: 1.5rem;
  }

  .commitment-section th,
  .commitment-section td {
    font-size: 0.9rem;
  }
.nested-table{
  width: 100%;
  height: auto;
  border-collapse: collapse;
}
  /* Table responsiveness */
  .commitment-section table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .commitment-section th,
  .commitment-section td {
    min-width: 120px;
    /* Adjust as necessary to fit content */
  }
}