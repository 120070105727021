.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 230px;
}

.pdf-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.iframe-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}


@media (max-width: 768px) {
  .pdf-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .iframe-container {
    padding-top: 75%;
  }
}
