/* General styling for the carousel */
.u-carousel-inner {
  display: flex;
  transition: transform 0.5s ease;
}

.u-carousel-item {
  min-width: 100%;
  box-sizing: border-box;
}

.u-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  position: relative;
}

/* Background images for each slide */
.u-image.image0 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../Asset/Gallery/IMG_0114.JPG');
}

.u-image.image1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../Asset/Gallery/IMG_0723.JPG');
}

.u-image.image2 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../Asset/Gallery/IMG_0093.JPG');
}

.u-image.image3 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../Asset/Gallery/IMG_0499.JPG');
}

.u-image.image4 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('../Asset/Gallery/IMG_0136.JPG');
}

.u-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
  z-index: 1;
}

.u-container-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 2;
}

/* Core values styling with slide-up animation */
.u-core-values {
  text-align: center;
  padding: 20px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.u-core-values h2 {
  font-size: 50px;
  color: #f39c12;
  margin-bottom: 15px;
}

.u-core-values p {
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
  color: white;
}

/* Active class that triggers the slide-up effect */
.u-slide-up {
  opacity: 1;
  transform: translateY(0);
}

/* Carousel controls */
.u-carousel-control-prev,
.u-carousel-control-next {
  position: absolute;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;
  z-index: 3;
}

.u-carousel-control-prev {
  left: 10px;
}

.u-carousel-control-next {
  right: 10px;
}

.u-carousel-control-prev svg,
.u-carousel-control-next svg {
  width: 20px;
  height: 20px;
  fill: white;
}

/* Carousel indicators */
.u-carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 3;
}

.u-carousel-indicators li {
  width: 10px;
  height: 10px;
  background-color: white;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.5;
}

.u-carousel-indicators .u-active {
  opacity: 1;
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
  .u-core-values h2 {
    font-size: 40px;
  }

  .u-core-values p {
    font-size: 18px;
  }

  .u-carousel-control-prev,
  .u-carousel-control-next {
    padding: 8px;
  }

  .u-carousel-control-prev svg,
  .u-carousel-control-next svg {
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 768px) {
  .u-core-values h2 {
    font-size: 30px;
  }

  .u-core-values p {
    font-size: 16px;
    max-width: 600px;
  }

  .u-carousel-control-prev,
  .u-carousel-control-next {
    padding: 6px;
  }

  .u-carousel-control-prev svg,
  .u-carousel-control-next svg {
    width: 14px;
    height: 14px;
  }

  .u-image {
    height: 70vh;
  }

  .u-carousel-indicators li {
    width: 8px;
    height: 8px;
  }
}

@media screen and (max-width: 480px) {
  .u-core-values h2 {
    font-size: 25px;
  }

  .u-core-values p {
    font-size: 14px;
    max-width: 400px;
  }

  .u-carousel-control-prev,
  .u-carousel-control-next {
    padding: 4px;
  }

  .u-carousel-control-prev svg,
  .u-carousel-control-next svg {
    width: 12px;
    height: 12px;
  }

  .u-image {
    height: 60vh;
  }

  .u-carousel-indicators li {
    width: 6px;
    height: 6px;
  }
}
