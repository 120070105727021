.footer-top {
  background-color: #1b1b1b;
  color: white;
  padding: 20px 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.col-lg-3 {
  flex: 1 1 22%;
  margin: 10px;
}

.footer-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #ffffff;
  position: relative;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #ffffff;
  transition: width 0.3s ease;
}

.footer-title:hover {
  transform: scale(1.1);
}

.footer-title:hover::after {
  width: 100%;
}


.footer-link {
  color: #dddddd;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
  transition: color 0.3s;
}

.footer-link:hover {
  color: #f39c12;
}

.footer_social {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.footer_social li {
  display: inline;
}

.footer_social a {
  color: white;
  font-size: 24px;
  transition: color 0.3s;
}

.footer_social a:hover {
  color: #f39c12;
}

.widget_contact_widget {
  padding-bottom: 20px;
  /* Adjust as needed */
}


.widget_contact_widget ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.widget_contact_widget ul li {
  margin-bottom: 10px;
  cursor: pointer;
}

.widget_contact_widget ul li:hover {
  color: #ffc107;
}

.widget_contact_widget ul li i {
  margin-right: 10px;
}

.widget_contact_widget ul li a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.widget_contact_widget ul li a:hover {
  color: #ffc107;
  text-decoration: underline;
}


.text-sm {
  font-size: 14px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-gray-600 {
  border-color: #4a4a4a;
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
    align-items: center;
  }

  .col-lg-3 {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 10px 0;
  }

  .footer-container {
    padding: 0 10px;
  }

  .footer_social {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .row {
    flex-direction: column;
    align-items: center;
  }

  .col-lg-3 {
    width: 100%;
    margin: 10px 0;
  }

  .footer_social {
    justify-content: center;
  }
}