.news-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .news-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .news-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  
  .news-item {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    transition: transform 0.3s ease;
  }
  
  .news-item:hover {
    transform: translateY(-5px);
  }
  
  .news-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .news-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  
  .news-item-title {
    font-size: 1.5rem;
    margin: 0 0 10px;
    color: #333;
  }
  
  .news-description {
    font-size: 1rem;
    margin: 0 0 15px;
    color: #666;
    flex-grow: 1;
  }
  
  .news-link {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
  }
  
  .news-link:hover {
    text-decoration: underline;
  }
  
  .news-date {
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
  }
  