body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.gallery {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.gallery-title {
    text-align: center;
    font-size: 2.5rem;
    color: #2d3748;
    margin-bottom: 2rem;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.gallery-item {
    cursor: pointer;
    overflow: hidden;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.gallery-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    display: block;
}

.lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.lightbox-image {
    max-width: 90%;
    max-height: 90%;
    border: 5px solid #fff;
    border-radius: 0.5rem;
}

.lightbox-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: #f5f4f200;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s ease;
}

.lightbox-close:hover {
    color: #f39c12;
}

.lightbox-prev,
.lightbox-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  z-index: 1001;
}

.lightbox-prev {
  left: 20px;
}

.lightbox-next {
  right: 20px;
}