.application-requirements {
    padding: 2rem;
    background-color: #f4f4f4;
  }
  
  .title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: bold;
    color: #333;
  }
  
  .section {
    margin-bottom: 2rem;
  }
  
  .section h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #444;
    margin-bottom: 1rem;
  }
  
  .section ul {
    list-style-type: disc;
    padding-left: 1.5rem;
  }
  
  .section ul li {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    color: #555;
  }
  
  .apply-now-container {
    text-align: center;
    margin-top: 2rem;
  }
  
  .apply-now-button {
    background-color: #007bff;
    color: white;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .apply-now-button:hover {
    background-color: #f39c12;
    color: #ffffff;
  }
  