.contact-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.contact-inner-container {
  max-width: 72rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.contact-map-section {
  background-color: white;
  padding: 2rem;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.contact-map-wrapper {
  text-align: center;
}

.contact-location-details {
  margin-top: 1rem;
}

.contact-location-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #f39c12;
}

.contact-info-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 2rem;
}

.contact-details-section,
.contact-hours-social-section {
  padding: 2rem;
  background-color: white;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

@media (min-width: 768px) {
  .contact-details-section,
  .contact-hours-social-section {
    width: 48%;
  }

  .contact-hours-social-section {
    margin-top: 0;
  }
}

.contact-section-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #f39c12;
}

.contact-hours-details {
  color: #2d3748;
  margin-bottom: 1.5rem;
}

.contact-social-media {
  color: #2d3748;
}

.contact-social-icons {
  display: flex;
  gap: 1rem;
}

.facebook-icon,
.twitter-icon,
.linkedin-icon {
  font-size: 1.875rem;
  transition: color 0.2s ease-in-out;
}

.facebook-icon {
  color: #3b5998;
}

.twitter-icon {
  color: #00acee;
}

.linkedin-icon {
  color: #0e76a8;
}

.facebook-icon:hover,
.twitter-icon:hover,
.linkedin-icon:hover {
  color: #f39c12;
}
