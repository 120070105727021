.apply-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.apply-section {
  text-align: center;
  margin-bottom: 20px;
}

.apply-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.apply-section p {
  font-size: 1.1rem;
  color: #666;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 15px;
}

.apply-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #5b92e5;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  animation: colorChange 3s infinite;
}

.apply-button:hover {
  background-color: #f39c12;
  color: #ffffff;
}


@keyframes colorChange {
  0% {
    background-color: #3498db; 
  }
  50% {
    background-color: #f39c12; 
  }
  100% {
    background-color: #3498db; 
  }
}