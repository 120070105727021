/* FAQ styles */
.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.faq-question {
  padding: 15px;
  background-color: #f5f5f5;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.faq-container h2 {
  font-size: 32px; 
  font-weight: bold;
  color: #000000;
  text-align: center; 
  margin-bottom: 40px; 
  padding: 10px; 
  border-bottom: 2px solid #ffc107; 
  display: inline-block; 
  text-transform: uppercase; 
  font-family: 'Arial', sans-serif; 
}

.faq-question::after {
  content: '';
  position: absolute;
  right: 15px;
  font-size: 16px;
  transition: transform 0.3s ease;
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(0deg);
}

.faq-question.active::after {
  transform: rotate(45deg);
}

.faq-answer {
  padding: 15px;
  background-color: #fff;
  display: none;
}

.faq-answer p {
  margin: 0;
  color:#000;
}

.faq-item.active .faq-answer {
  display: block;
}
