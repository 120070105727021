.top-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #5b92e5;
  width: 100%;
  top: 0;
  font-weight: 600;
  border-bottom: 6px #f39c12 solid;
  position: fixed;
  z-index: 1000;
}

.toolbar-area {
  width: 100%;
  background-color: #f39c12;
  color: white;
  margin-bottom: 10px;
}

.toolbar-area .menu-lists li {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.company-name {
  color: #5b92e5;
  font-size: 1.75rem;
  margin-left: 10px;
  white-space: nowrap; /* Prevents text from breaking into new lines */
}

.logo-img {
  width: 120px;
  height: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  margin-right: 20px;
  padding: 10px 20px;
  transition: opacity 0.3s ease;
}

.nav-links.hidden {
  display: none;
}

.nav-link {
  color: #5b92e5;
  text-decoration: none;
  margin-right: 20px;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #f39c12;
  transform: scale(1.1);
  border-bottom: 2px solid #f39c12;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  cursor: pointer;
}

.dropdown-link {
  display: flex;
  align-items: center;
  color: #5b92e5;
  transition: color 0.2s ease;
}

.dropdown-link i {
  margin-left: 5px;
}

.dropdown-link:hover {
  color: #f39c12;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  white-space: nowrap;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 220px;
  padding: 10px 0;
  z-index: 10;
  opacity: 0;
  transform-origin: top center;
  transform: scaleY(0);
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.dropdown-content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-content li {
  padding: 10px 20px;
  position: relative;
}

.dropdown-content li:not(:last-child) {
  border-bottom: 2px solid transparent;
}
 
.dropdown-content li:hover {
  background-color: #f3f3f3;
  border-bottom: 2px solid #f39c12;
}

.dropdown-content li a {
  color: #5b92e5;
  text-decoration: none;
  display: block;
  transition: color 0.2s ease;
}
  
.dropdown-content li a:hover {
  color: #f39c12;
}

.dropdown:hover .dropdown-content {
  opacity: 1;
  transform: scaleY(1);
}

.active {
  color: #f39c12;
}
.mobile-container{
  display:none;
}

.hamburger {
  display: none;
  
}

.hamburger i {
  transition: transform 0.3s ease;
}

.hamburger:hover i {
  color: #f39c12;
}

@media (max-width: 768px) {
  /* Style the top-bar to be a simple toolbar with no company name */
  .top-bar {
    flex-direction: column; /* Stack elements vertically */
    justify-content: center; 
    align-items: center;
    background-color: #f39c12;
    color: white;
  }

  /* Hide the company name on mobile */
  .logo-container {
    display: none;
  }
  .company-name {
    display: none;
  }

  /* Style the logo container and hamburger menu to appear below the toolbar */
  .mobile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #fff; /* Ensure the background contrasts the toolbar */
  }

  /* Adjust logo size for mobile */
  .logo-img {
     display:none;
  }
  .mobile-logo {
    width: 100px;
    height: auto;
    justify-content: flex-start;
  }

  /* Display the hamburger menu to the right of the logo */
  .hamburger {
    display: block;
    font-size: 24px;
    cursor: pointer;
    color: #5b92e5;
  }

  .hamburger i {
    transition: transform 0.3s ease;
  }

  .hamburger:hover i {
    color: #f39c12;
  }

  /* Hide navigation links on mobile */
  .nav-links {
    display: none;
  }

  /* Style the toolbar area and menu lists */
    .toolbar-area {
    flex-direction: row; 
    justify-content: space-between; 
  }

  toolbar-area .menu-lists {
    flex-direction: row; /* Stack menu items vertically */
    width: 100%; /* Ensure the menu takes up full width */
  }

  .toolbar-area .menu-lists li {
    margin-bottom:10px;
    margin-right: 15px; 
  }
  }
