/* Container to center the spinner and text */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f3f3f3; /* Light gray background */
    font-family: Arial, sans-serif; /* Use a clean, sans-serif font */
  }
  
  /* Styling the spinner */
  .multi-circle-spinner {
    position: relative;
    width: 64px;
    height: 64px;
    margin-bottom: 20px; /* Space between spinner and text */
  }
  
  .circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #09f; /* Blue color for the circles */
    position: absolute;
    animation: circle-animation 1.5s infinite ease-in-out;
  }
  
  /* Position circles around the center */
  .circle1 { top: 0; left: 50%; transform: translateX(-50%); }
  .circle2 { top: 50%; left: 100%; transform: translate(-100%, -50%); }
  .circle3 { bottom: 0; left: 50%; transform: translateX(-50%); }
  .circle4 { top: 50%; left: 0; transform: translate(-0%, -50%); }
  
  /* Animation for the circles */
  @keyframes circle-animation {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.5);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  .loading-container h2 {
    margin: 0;
    color: #333; 
    font-size: 24px; 
    font-weight: bold;
  }
  
  .loading-container p {
    margin: 0;
    color: #555; 
    font-size: 18px; 
    font-weight: normal;
  }
  