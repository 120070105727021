/* MobileMenu.css */
.mobile-menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    z-index: 1000;
    display: flex;
    padding:10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .mobile-menu.active {
    transform: translateX(0);
  }
  
  .menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    color: #5b92e5;
    cursor: pointer;
  }
  
  .mobile-nav-links {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .mobile-nav-link {
    color: #5b92e5;
    text-decoration: none;
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    width: 100%;
    transition: color 0.2s ease;
  }
  
  .mobile-nav-link:hover {
    color: #f39c12;
    background-color: #f9f9f9;
  }

.mobile-nav-link {
  padding: 10px;
  text-decoration: none;
}
