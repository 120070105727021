#page-title {
    text-align: center;
    font-size: 2.5rem;
    margin-top: 2rem;
    color: #2d3748;
}

.mandate-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 2rem;
    margin: 0 auto;
    max-width: 1200px;
}

.mandate-item {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.mandate-item:hover {
    transform: translateY(-5px);
}

.mandate-icon {
    font-size: 10rem;
    color: #f39c12;
    margin-right: 1rem;
}

.mandate-item p {
    font-size: 1rem;
    color: #2d3748;
    margin: 0;
}

@media (min-width: 768px) {
    .mandate-item p {
        font-size: 1.1rem;
    }
}
