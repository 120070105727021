.tender-page-container {
    padding-top: 170px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    text-align: center; 
    height:90vh;
  }
  
  .animation-wrapper {
    display: flex;
    gap: 20px;
    justify-content: center; 
    margin-bottom: 20px; 
  }
  
  .circles {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #4A90E2;
    animation: bounce 1.5s infinite ease-in-out;
  }
  
  .circle6 {
    animation-delay: 0s;
  }
  
  .circle7 {
    animation-delay: 0.3s;
  }
  
  .circle8 {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }
  
  .coming-soon-title {
    margin-top: 20px;
    font-size: 1.5rem; 
  }
  
  .coming-soon-text {
    max-width: 600px;
    margin: 0 auto; 
    font-size: 1rem; 
  }
  
  .back-link {
    margin-top: 20px;
    font-weight: 500;
    text-decoration: underline; 
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .coming-soon-title {
      font-size: 1.25rem; 
    }
  
    .coming-soon-text {
      font-size: 0.9rem; 
    }
  
    .animation-wrapper {
      gap: 10px; 
    }
  
    .circles {
      width: 40px; 
      height: 40px;
    }
  }
  
