/* About.css */

.about-container {
    padding: 20px;
    resize: both;
    overflow: auto;
}

.about-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
}

.about-text {
    flex: 1;
    padding-right: 20px;
}

.about-image-item img {
    object-fit: cover;
    max-height: 100%;
}

.about-image-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-heading {
    color: #f39c12;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
}

.about-subheading {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.about-description {
    color: #000000;
    font-size: 18px;
    line-height: 1.5;
}

.read-more-btn {
    margin-top: 30px;
}

.read-more-link {
    color: #000000;
    background-color: #ffffff00;
    padding: 12px 24px;
    border: 2px solid #fff;
    border-radius: 30px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
}

.about-image {
    flex: 1;
    text-align: center;
}

.about-image-item {
    width: 100%;
    height: auto;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}
.about-special ul {
    margin: 10px 0;
    padding-left: 20px;
    list-style-type: disc;
}
  
.about-special  ul li {
    margin-bottom: 10px;
    line-height: 1.5;
    color: #333; 
  }
  
.about-special  p {
    margin-bottom: 10px;
    font-weight: bold;
    color: #333;
  }
  .partners-content {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
    gap: 20px; 
  }
  
  .partner-item-large {
    height: 200px; 
    width:500px;
    object-fit: contain;
  }

  .partner-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .partners-content {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .partner-logo {
    max-width: 80%;
    max-height: 100px;
    object-fit: contain;
    transition: transform 0.3s ease;
  }
  
  .partner-logo:hover {
    transform: scale(1.05);
  }
  
